import HelpIcon from 'assets/icons/help-grey.svg';
import ResetIcon from 'assets/icons/reset.svg';
import Card from 'components/Card';
import CustomTooltip from 'components/Tooltip';
import { IGrowthEnterprise } from 'interfaces/components/growthEnterprise';
import { useAppDispatch, useAppSelector } from 'store';
import { resetCompanyInfo } from 'store/companyInfo/companyInfo.slice';
import { formatMoney, formatThousands } from 'utils/helpers';

const GrowthEnterprise = ({ onUpdate, onReset }: IGrowthEnterprise) => {
  const dispatch = useAppDispatch();
  const companyInfo = useAppSelector(state => state.companyInfo);

  const handleReset = () => {
    onReset();
    dispatch(resetCompanyInfo());
  };

  return (
    <div className='w-full flex flex-col gap-7 py-6 px-20'>
      <div>
      <div className='flex flex-row w-full justify-between items-center'>
        <div className='flex flex-row gap-1 items-center'>
          <p className='text-3xl font-light'>
            ICONIQ Growth Enterprise Five: Your Metrics
          </p>
          <CustomTooltip
            id='enterprise-five'
            content='Our team has developed a set of metrics that can serve as goalposts for individual SaaS businesses, as well as guideposts indicating where we are in the cycle: The ICONIQ Growth Enterprise Five. Looking at these five figures—ARR Growth, Net $ Retention, Rule of 40, Net Magic Number, and ARR per FTE—can help you determine the health of your business. We often see startups include these metrics in board decks and pitches as a way of signaling growth prospects, while also using this information to support strategic planning decisions around everything from GTM strategy to team size.'
            icon={HelpIcon}
            className='w-7 h-7'
          />
        </div>
        <div className='flex flex-row items-center'>
          {/* <div
            className='flex flex-row items-center gap-1 pr-4 border-r-[1px] border-gray-400'
            onClick={handleReset}
          >
            <img src={ResetIcon} alt='' />
            <p className='text-lg font-light cursor-pointer'>Reset View</p>
          </div> */}
          <p
            className='text-lg font-light cursor-pointer ml-4'
            onClick={onUpdate}
          >
            Update Your Data
          </p>
        </div>
      </div>
      <div>
        <p>
          <a href=' https://www.iconiqcapital.com/growth/insights/iconiq-growth-enterprise-five' target="_blank" rel="noopener noreferrer"
                          className=' text-blue-400 border-none text-base font-light'>
          Read more
        </a>
        </p>
      </div>
      </div>
      <div className='flex gap-2 items-center flex-row justify-between'>
        <Card
          value={
            companyInfo.requiredInfo.yoyARR
              ? `${companyInfo.requiredInfo.yoyARR}%`
              : 'N/A'
          }
          description='ARR Growth'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.otherInfo.netDollarRetention
              ? `${companyInfo.otherInfo.netDollarRetention}%`
              : 'N/A'
          }
          description='Net Dollar Retention'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.requiredInfo.yoyARR &&
            companyInfo.requiredInfo.fcfMargin
              ? `${
                  companyInfo.requiredInfo.yoyARR +
                  companyInfo.requiredInfo.fcfMargin
                }%`
              : 'N/A'
          }
          description='Rule of 40'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.otherInfo.netMagicNumber
              ? `${companyInfo.otherInfo.netMagicNumber}x`
              : 'N/A'
          }
          description='Net Magic Number'
          onUpdate={onUpdate}
        />
        <Card
          value={
            companyInfo.requiredInfo.currentARR &&
            companyInfo.requiredInfo.totalHeadcount
              ? `$${formatThousands(
                  companyInfo.requiredInfo.currentARR /
                    companyInfo.requiredInfo.totalHeadcount,
                  0
                )}`
              : 'N/A'
          }
          description='ARR per FTE'
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
};

export default GrowthEnterprise;
